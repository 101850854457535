.materialbox-caption {
  /* position: absolute !important;
    top: 0px !important;
    font-size: 40px;
    left: 25rem;*/
}

#myImg {
  border-radius: 5px;
  cursor: pointer;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  width: 50%;
}

#myImg:hover {
  opacity: 0.7;
}

/* The Modal (background) */
.modal-img {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 4000;
  /* Sit on top */
  padding-top: 100px;
  /* Location of the box */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  /*overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0, 0.8);
  /*background-color: rgba(54, 54, 54, 0.06)*/
  /* Fallback color */
  /*background-color: #545453;*/
  /* Black w/ opacity */
  /*background-color: rgba(84, 84, 83, 0.9)*/
}

/* Modal Content (image) */
.modal-content-img {
  margin: auto;
  display: block;
  /*width: 80%;*/
  max-width: 80%;
  max-height: 90%;
}

/* Caption of Modal Image */
#caption {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
  text-align: center;
  color: #ccc;
  padding: 10px 0;
  height: 150px;
}

/* Add Animation */
.modal-content,
.modal-content-img,
#caption {
  /*-webkit-animation-name: zoom;
  -webkit-animation-duration: 0.6s;
  animation-name: zoom;
  animation-duration: 0.6s;*/
}

@-webkit-keyframes zoom {
  from {
    -webkit-transform: scale(0)
  }

  to {
    -webkit-transform: scale(1)
  }
}

@keyframes zoom {
  from {
    -webkit-transform: scale(0);
    transform: scale(0)
  }

  to {
    -webkit-transform: scale(1);
    transform: scale(1)
  }
}

/* The Close Button */
.close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

img[alt="modal-img-close"] {
  width: 28px;
  height: 28px;
}

/* 100% Image Width on Smaller Screens */
/*@media only screen and (max-width: 700px) {
  .modal-content {
    width: 100%;
  }
}*/
