img[alt="default-platform"] {
  width: 319px;
  height: 298px;
  margin-top: 10px;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
