.admin-parameters__item-session {
  display: flex;
  flex-direction: column;
  color: #4a4a4a;
  margin-bottom: 10px;

}

.admin-parameters_description {
  width: 142px;
  text-transform: lowercase;
  margin-top: 0rem;
  margin-bottom: 0rem;
}

.admin-parameters_description:first-letter {
  text-transform: uppercase;
}



.admin-parameters_inputs {
  border-radius: 5px;
  border: 2px solid #e3e3e8;
  width: 90%;
  margin-left: 0rem;
  display: flex;
  flex-direction: row;
  color: #e3e3e8;
  padding-right: 5px;
  font-weight: 600;
  text-align: left;
}

.admin-parameters_inputs input[type=text]:not(.browser-default) {
  text-align: left;
}

input[type=text].input-default:not(.browser-default):focus:not([reandoly]),
input[type=text].input-default:not(.browser-default):active:not([reandoly]) {
  border-bottom: none;
}

.admin-parameters_asterisk {
  color: #496ffb;
  font-size: 20px;
  margin-left: 5px;
  font-weight: 600;
}

/*umbrales*/
.item--threshold-cancelation {
  background-color: white;
  color: #4a4a4a;
}

.item--threshold-low {
  background-color: #ffe19c;
  color: #4a4a4a;
}

.item--threshold-high {
  background-color: #ffbd9e;
  color: #4a4a4a;
}

.admin-parameters__container {
  display: flex;
  flex-direction: row;
}

@media (min-width: 600px) {
  .admin-parameters__item-session {
    flex-direction: row;

  }

  .admin-parameters_inputs {

    /* width: 150px;*/
  }
}
