.Rectangle {
  width: 100%;
  height: 90vh;
  -webkit-box-shadow: 5px 5px 8px 0 rgba(0, 0, 0, 0.09);
  box-shadow: 5px 5px 8px 0 rgba(0, 0, 0, 0.09);
  background-color: #ffffff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  /*outline: 1px solid blue;*/
}

.Imagen {
  width: 100%;
  max-width: 600px;
  height: 500px;

  /*width: 50px;*/
  /*height: 50px;*/
  -o-object-fit: contain;
  object-fit: contain;
}

.Inicia-una-conversac {
  /*width: 266px;
  height: 42px;*/
  font-family: Roboto;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #aeabab;
}
