/*.outline1{
    outline: 1px solid red;
}*/

.container-listchat {
  width: 100%;
  background-color: white;
  -webkit-box-shadow: 0 8px 30px 0 rgba(9, 33, 57, 0.2);
  box-shadow: 0 8px 30px 0 rgba(9, 33, 57, 0.2);
  height: 66vh;
  overflow: auto;
  margin-right: 16px;
}

.container-listchat::-webkit-scrollbar {
  width: 8px;
  height: 8px;

}

.container-listchat::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 4px;
}

.container-listchat::-webkit-scrollbar-thumb:hover {
  background: #b3b3b3;
  -webkit-box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

.container-listchat::-webkit-scrollbar-thumb:active {
  background-color: #999999;
}

.container-listchat::-webkit-scrollbar-track {
  background: #e1e1e1;
  border-radius: 4px;
}

.container-listchat::-webkit-scrollbar-track:hover,
.container-listchat::-webkit-scrollbar-track:active {
  background: #d4d4d4;
}


@media (min-width: 992px) {
  .container-listchat {
    /*margin-left: 36px;*/
    /*width: 278px;*/
    width: 100%;
  }

}

@media (min-width: 1200px) {
  .container-listchat {
    /*margin-left: 36px;*/
    /*width: 278px;*/
    width: 100%;
  }

}
