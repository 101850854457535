.container_ticket {
  color: #4a4a4a;
}

.ticket_label {
  font-weight: bold;
}

.container-field {
  border: 1px solid #e3e3e8;
  width: 100%;
  border-radius: 5px;
  min-height: 200px;

  padding: 10px;
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
}

.ticket_add_state {
  color: #467dfc;
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
  font-size: 14px;
  /*width: 100%;*/
  text-align: right;


}
