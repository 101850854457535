.input-field-label{
    height: 42px;
    padding-top: 12px;
}


select{
    display: block;
}

.container-buttoms{
    display: flex;
    justify-content: flex-end;
    padding-top: 10px;
}

.container-option-link{
    display: block;
    padding-top: 10px;
}

.container-option-link .option-link{
    float: right;
    padding-right: 10px;
    padding-left: 10px;
    border-right: 1px solid #496ffb;
    color: #496ffb;
    font-size: 14px;
}

.container-option-link .option-link:first-child{
    border-right: 1px solid transparent;
}

.container-option-link .option-link img{
    float: left;
}

.container-option-link .option-link span + a.option-link-text{
    width: calc( 100% - 30px );
    float: left;
    color: #496ffb;
}

.container-option-link .option-link a.option-link-text{
    float: left;
    color: #496ffb;
}