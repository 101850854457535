input[type="date"] {
  appearance: none;
  -webkit-appearance: none;
}


[type="date"] {
  background: #fff url(https://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/calendar_2.png) 97% 50% no-repeat;
}

[type="date"]::-webkit-inner-spin-button {
  display: none;
}

[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 0;

}

::-ms-reveal {
  display: none;
}

/* custom styles */

label {
  display: block;
}

input {
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  background-color: #fff;
  padding: 3px 5px;
  box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.1);
  width: 190px;
}

.dashboard__container-date {
  margin-left: 1rem;
  margin-top: 2rem;
}

.dashboard__title {
  color: #467dfc;
  font-size: 16px;
  text-transform: none !important;
  margin-bottom: 1.5rem;
}

.dashboard__title-filter {
  text-transform: uppercase;
  color: #a3a3a3;
}

.dashboard__input-filter {
  border: 1px solid #e3e3e8 !important;
  border-radius: 5px !important;
  padding-left: 10px;
  /*padding: 1px !important;*/
}

.chart_label_canva::first-letter {
  text-transform: capitalize !important;
  /*outline: 1px solid red;*/
}
