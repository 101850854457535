img[alt="img-replies"] {
  width: 22px;
  height: 22px;
  margin-right: 8px;
}

.admin-replies__container-message {
  display: flex;
  flex-direction: row;
  /*align-items: center;
  justify-content: center;*/
  margin-top: 2rem;
}

.admin_replies_container-modal {
  width: 380px;
  height: auto;
  background-color: white;
  position: relative;
  left: 40%;
  border-radius: 9px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 18px;
  padding-bottom: 18px;
}

.admin_replies__max_length_text {
  font-size: 12px;
  margin: 0px;
}

.admin-replies__text-blue {
  color: #467dfc;
  font-size: 14px;
}

.admin-replies_container-input {
  /*border: 1px solid #467dfc;*/
  width: 100%;
  border-radius: 5px;
  padding: 5px;
}

.admin-replies__container-messages {
  margin-top: 1.5rem;
  display: flex;
  justify-content: center;
}

.admin-replies__btn-save {
  background-color: #467dfc;
  width: 97px;
  height: 35px;
  border: none;
  border-radius: 20px;
  color: white;
  margin-right: 10px;
  margin-left: 10px;
}

.admin-replies__btn-delete {
  background-color: white;
  width: 97px;
  height: 35px;
  border: 1px solid #467dfc;
  border-radius: 20px;
  color: #467dfc;
  margin-right: 10px;
  margin-left: 10px;
}

.admin-replies__btn-save:active {
  background-color: #467dfc !important;
}


.admin-replies__btn-delete:active {
  background-color: white;
  color: #467dfc;
}

.admin-replies__btn-delete:focus {
  background-color: white;
  color: #467dfc;
}

.admin-replies__btn-save:focus {
  background-color: #467dfc !important;
}

.admin-replies__add-message {
  display: flex;
  flex-direction: row;
  /*outline: 2px solid red;*/
  width: 20%;
  margin-left: 5px;
}

.modal.admin-modal__add-message {

  width: 30%;
  height: 40vh;
  border-radius: 10px;
  bottom: 0rem;

}

.admin-modal__title {
  color: #606363;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 1rem;
  text-align: center;
}

.modal-content.admin-modal__content {
  text-align: center;
  color: #606363;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

textarea {
  border: 1px solid #467dfc;
  color: #606363;
  resize: none;

}