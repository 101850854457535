.mystyle {
  display: none;
}

.navbar {
  position: fixed;
  width: 100%;
  background-color: #4a4a4a;
  color: #ffffff;
  /*background-color: #ffffff;
  //color: #4a4a4a;*/
  z-index: 1000;
  height: 50px;
  margin-bottom: 14px;
  -webkit-box-shadow: 0 8px 15px 0 rgba(72, 89, 102, 0.11);
  box-shadow: 0 8px 15px 0 rgba(72, 89, 102, 0.11);
}

.navbar .nav-wrapper span {
  top: -7px;
  position: relative;
}

nav ul a {
  /*color: #ffffff !important;*/
}

nav ul a:hover {
  color: #ffffff;
  background-color: transparent;
}

.brand-logo {
  color: white;
  /*color: #4a4a4a !important;*/
  font-size: 14px;
  width: 100%;
}

.brand-logo div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 50px;
  /*color: white;*/
  font-size: 15px;
}

.logo {
  width: 24px;
  height: 27px;
  margin-left: 50px;
  color: #4a4a4a;
  margin-right: 10px;
}

img[alt="icon-sound"] {
  width: 20px;
  height: 20px;
  /*margin-right: 15rem;*/
}

/*img[alt="logout"] {
    width: 27px;
    height: 22px;
    position: absolute;
    right: 1%;
    bottom: 27%;
}*/

.item-navbar {
  /*outline: 1px solid red;*/
  margin-left: 16px;
}

.switch-sound {
  width: 48px;
  height: 24px;
  border-radius: 12px;
  background-color: #f1f1f1;
  /*justify-content: space-around;**/
  /*justify-content: space-evenly;*/
}

.item-navbar:nth-child(2) {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 15px;
}


/*
.container {
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    height: 40px;
    margin: auto;
    text-align: center;
  }
  */


/** Switch
   -------------------------------------*/

.switch input {
  position: absolute;
  opacity: 0;
}


/**
   * 1. Adjust this to size
   */

.switch {
  display: inline-block;
  font-size: 20px;
  /* 1 */
  height: 1em;
  width: 2em;
  background: #BDB9A6;
  border-radius: 1em;
}

.switch div {
  height: 1em;
  width: 1em;
  border-radius: 1em;
  background: #FFF;
  -webkit-box-shadow: 0 0.1em 0.3em rgba(0, 0, 0, 0.3);
  box-shadow: 0 0.1em 0.3em rgba(0, 0, 0, 0.3);
  -webkit-transition: all 300ms;
  transition: all 300ms;
}

.switch input:checked+div {
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}

.btn-switch {
  width: 16px;
  height: 16px;
  background-color: #ff5800;
  border-radius: 50%;
  /*margin-left:3px;*/
}

.btn-switch:nth-child(1) {
  margin-top: 3px;
  margin-left: 4px;
  float: left;
  cursor: pointer;
}

.btn-switch:nth-child(2) {
  margin-top: 3px;
  margin-right: 4px;
  /*background-color: red;*/
  float: right;
  cursor: pointer;
}

/*img[alt="sound-icon"] {
    width: 41px;
    position: absolute;
    position: absolute;
    right: 61px;
    top: 10px;
}*/

img[alt="silence-icon"] {
  width: 25px;
  position: absolute;
  right: 67px;
  top: 15px;
}

.navbar-options {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 300px;
  height: 50px;
}

.navbar-options li {
  /*margin-top: 15px;*/
  max-height: 50px;
}

.navbar-options li a {
  margin-top: -7px;
}

.navbar-options li img {
  max-height: 20px;
  margin-left: 15px;
  cursor: pointer;
}

.navbar-options li img.img-min {
  max-height: 30px;
  margin-top: 15px;
}
