.admin-parameters__item-session {
  display: flex;
  flex-direction: column;
  color: #4a4a4a;
  margin-bottom: 10px;

}

.admin-parameters_description {
  width: 142px;
  text-transform: lowercase;
  margin-top: 0rem;
  margin-bottom: 0rem;
}

.admin-parameters_description:first-letter {
  text-transform: uppercase;
}



.admin-parameters_input {
  border-radius: 5px;
  border: 1px solid #e3e3e8;
  width: 90%;
  margin-left: 0rem;
  display: flex;
  flex-direction: row;
  color: #e3e3e8;
  padding-right: 5px;
  align-items: center;
  text-align: right;


}

input[type=number]:not(.browser-default) {
  border-bottom: none;
  text-align: right;
  padding-right: 5px;
  margin-top: 5px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

input[type=text]:not(.browser-default) {
  text-align: right;

  display: flex;
  justify-content: center;
  margin-bottom: 3px;
  margin-right: 5px;
}

.admin-parameters_asterisk {
  color: #496ffb;
  font-size: 20px;
  margin-left: 5px;
  font-weight: 600;
}

input[type=number].input-default:not(.browser-default):focus:not([reandoly]),
input[type=number].input-default:not(.browser-default):active:not([reandoly]) {
  border-bottom: none;
}

@media (min-width: 600px) {
  .admin-parameters__item-session {
    flex-direction: row;

  }

  .admin-parameters_input {

    width: 150px;
  }
}
