.emoji-mart {
  font-family: 'Roboto', sans-serif !important;
  font-size: 12px;
  border: 1px solid white;
  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  /*box-shadow: 10px 10px rgba(0, 0, 0, 0.3);*/
}

.emoji-mart-anchors {
  height: 35px;
}

.emoji-mart-search {
  height: 30px;
}

.emoji-mart-search input[type=search]:not(.browser-default) {
  height: 2rem;
  border-bottom: none;
  font-size: 12px;
}

.emoji-mart-scroll::-webkit-scrollbar {
  width: 0.65em;
  height: 0.65em;
}

.emoji-mart-scroll::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
}

.emoji-mart-scroll::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.3);
}

.emoji-mart-bar:last-child {
  display: none;
}

.emoji-mart-anchors {
  background-color: white !important;
}

/**/
img[ alt="clip"] {}

/**/

.container-doc {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  /*justify-content: center;*/
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;

}

.sec {
  /*padding: 1.5em;*/
  /*margin-bottom: 1em;
	background-color: #fff;*/
  /*outline: 1px solid red;*/
}

.chatbot-conversation__clip {
  width: 5%;
  margin-left: 8px;
}

img[alt="icon-clip"] {
  width: 13px;
  margin-left: 3px;
}

.sec.sec-select {
  font-size: 2em;
  text-align: center;
  font-weight: 200;
  padding: 1.5em 1em;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;

  -webkit-transition: all 200ms ease;

  transition: all 200ms ease;
}

.sec.sec-select.dragenter {
  -webkit-box-shadow: inset 0 0 0 10px #23aeff;
  box-shadow: inset 0 0 0 10px #23aeff;
  background-color: rgba(35, 174, 255, 0.03);
  border-radius: 2px;
}

#file {
  display: none;
}

/*
.btn {
	display: inline-block;
	background-color: #23aeff;
	color: #fff;
	font-size: 0.5em;
	font-weight: normal;
	padding: 0.7em 1.5em;
	border-radius: 2px;

	transition: all 200ms ease;
	margin-top: 2em;
}
.btn:hover {
	transform: scale(1.05);
}
.btn:active {
	background-color: #2198de;
	transform: scale(0.98);
	transition: all 70ms ease;
}

*/
#list {
  margin: 0;
  padding-left: 0;
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-left: 18px;
  padding-top: 3px;
  /*overflow-x: scroll;*/
}

#list>li {
  /*padding: 1em;*/
  /*border: 1px solid #f0f0f0;*/
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  padding-top: 5px;
  /*outline: 1px solid red;*/
  /*width: 4.5em;*/
  /*height: 4em;*/
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  /*outline: 1px solid blue;*/
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 10px;

  /*display: flex;*/
}

#list>li:hover {
  background-color: #fafafa;
  -webkit-box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.05);
}

#list>li>.thumb-wrapper {
  width: 4.5em;
  height: 4.5em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  /*border: 1px solid rgba(0,0,0,0.1);*/
  padding: 3px;
  border-radius: 2px;
  background-color: #fafafa;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
  /*outline: 1px solid orange;*/
}

#list>li:hover>.thumb-wrapper {
  background-color: #fff;
}

#list>li>.thumb-wrapper>img {
  display: block;
  max-width: 100%;
  max-height: 100%;
  height: auto;
  margin: auto;
  min-width: 100%;
  border-radius: 10px;
}

/*#list > li > .thumb-wrapper >*/
.thumb-ext {
  width: 30px;
  height: 30px;
  text-align: center;
  font-size: 1.4em;
  color: white;
  text-transform: lowercase;
  font-size: 10px;
  border-radius: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-right: 5px;
}

#list>li>.file-info {
  padding-left: 1em;
  /*display: inline-flex;
	flex-direction: column;*/
  font-size: 8px;
  width: 50px;
  word-break: break-all;
  /*outline: 1px solid red;*/
}

#list>li>.file-info>div:first-child {
  font-weight: bold;
  margin-bottom: 0.5em;
  width: 61px;
  height: 100%;
  word-break: break-all;
  color: #4a4a4a;
}

#list>li>.file-info>div+div {
  font-size: 0.9em;

}

.file__delete-button {
  border-radius: 50%;
  width: 21px;
  height: 21px;
  background-color: #c0c0c0;
  position: relative;
  color: white;
  text-align: center;
  /*opacity: 0;*/
  cursor: pointer;
  min-width: 21px;
  padding: 3px 4px;
}

.file__delete-button-file {
  /*left: 3%;*/
  bottom: 4px;
}

.file__delete-button-img {
  right: 7%;
  top: -1rem;
}

.file__delete-button:hover {
  opacity: 1;
}















.ref {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  position: fixed;
  z-index: 10000;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  color: #fff;
  padding: 1rem 0;
  background-image: linear-gradient(135deg, rgba(60, 88, 173, 0.8), rgba(41, 152, 172, 0.8));
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

.ref a {
  color: #fff;
}

.thumb-ext-word {
  background-color: #005db9;
}

.thumb-ext-other {
  background-color: #00a4e4;
}

.thumb-ext-pdf {
  background-color: red;
}

.thumb-ext-excel {
  background-color: #1f6f43;
}

.file-info {
  color: #4a4a4a;
  font-size: 10px;
}

.prueba-container-file {
  border-top: 1px solid #f1f1f1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

/**/
.container-file {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  /*margin-left: 15px;*/
  padding-top: 5px;
  font-size: 0.7rem;
  padding-left: 15px;
  padding-right: 15px;
  overflow: auto;
}

.container-file div {
  padding-right: 5px;
  /*max-width: 130px;*/
}


.container-file .content-file {
  border: 1px solid #cecece;
  border-radius: 5px;
  width: 180px;
  padding: 0;
  margin-right: 5px;
  min-width: 180px;
}

.container-file .content-file .thumb-ext {
  border-radius: 5px 0 0 5px;
}

.container-file .content-file div {
  float: left;
  padding: 0;
  margin: 0;
}

.container-file .content-file .file-info {
  height: 30px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  /* padding: 4px; */
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 148px;
  padding: 8px 5px;
}


.thumb-ext {
  width: 30px;
  height: 30px;
  text-align: center;
  font-size: 1.4em;
  color: white;
  text-transform: lowercase;
  font-size: 10px;
  border-radius: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-right: 5px;
}

.file-info {
  color: #4a4a4a;
  font-size: 10px;
}

.thumb-ext-word {
  background-color: #005db9;
}

.thumb-ext-other {
  background-color: #87F0FF;
}

.thumb-ext-pdf {
  background-color: red;
}

.thumb-ext-excel {
  background-color: #1f6f43;
}

.chatbot-conversation__file-image {
  width: 100%;
  min-width: 2em;
  max-width: 4em;
  height: 100%;
  min-height: 2em;
  max-height: 5em;
  border-radius: 10px;

}

/*file image*/
img[alt="img-file"] {
  /*	width: 80%;*/
  width: 100%;
  min-width: 4em;
  max-width: 10em;
  height: 100%;
  min-height: 4em;
  max-height: 10em;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.docs {
  /*justify-content: flex-end;*/
  border: 1px solid #dfe1e2;
  margin-bottom: 5px;
  border-radius: 20px;

  /*	max-width: 50%;*/

}

.time-message {
  text-align: right;
  font-size: 9px;
  color: rgba(74, 74, 74, 0.4);
  margin-top: 0px;
  margin-bottom: 4px;
  /*color:rgba(241,243,244, 0.8);*/
}

.file-docs {
  padding: 5px;
}

.container-docs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin-bottom: 22px;
}

.download {
  text-decoration: underline;
  font-size: 12px;
}

.text-file {
  background-color: #f1f3f4;
  margin-bottom: 0px;
  margin-top: -13px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding-left: 10px;
  padding-right: 10px;
  color: #4a4a4a;
  word-break: break-all;
}

.container-only-docs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  border: 1px solid #dfe1e2;
  margin-bottom: 5px;
  border-radius: 20px;

  max-width: 30%;
}

.file-docs {
  border: 1px solid #dfe1e2;

}

.docs-ref {
  max-width: 30%;
}

.box-extension {
  width: 50px;
  height: 50px;
  padding: 10px;
  text-align: center;
  font-size: 1.4em;
  color: white;
  text-transform: lowercase;
  font-size: 10px;
  border-radius: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-right: 5px;
  /*background-color: #00a4e4;*/
  background-color: #87F0FF;
  ;
}

.box-extension--pdf {
  background-color: red;
}

.box-extension--word {
  background-color: #005db9;
}

.box-extension--xlsx {
  background-color: #1f6f43;
}

.description-file {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  /*width: 200px;*/
}

.file-only {
  padding: 5px;
  border: none;
}

.loader {

  width: 8px;
  height: 8px;
  /*margin: 40px auto;*/
  opacity: 0.9;
  border-radius: 50%;
  margin: 20px;
  -webkit-box-shadow:
    0 -14px 0 rgba(125, 128, 133, 0.8),
    10px -10px 0 rgba(125, 128, 133, 0.7),
    14px 0px 1px rgba(125, 128, 133, 0.6),
    10px 10px 1px rgba(125, 128, 133, 0.5),
    0px 14px 2px rgba(125, 128, 133, 0.4),
    -10px 10px 2px rgba(125, 128, 133, 0.3),
    -14px 0px 3px rgba(125, 128, 133, 0.2),
    -10px -10px 3px rgba(125, 128, 133, 0.1);
  box-shadow:
    0 -14px 0 rgba(125, 128, 133, 0.8),
    10px -10px 0 rgba(125, 128, 133, 0.7),
    14px 0px 1px rgba(125, 128, 133, 0.6),
    10px 10px 1px rgba(125, 128, 133, 0.5),
    0px 14px 2px rgba(125, 128, 133, 0.4),
    -10px 10px 2px rgba(125, 128, 133, 0.3),
    -14px 0px 3px rgba(125, 128, 133, 0.2),
    -10px -10px 3px rgba(125, 128, 133, 0.1);
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-animation: load 0.5s steps(8, end) infinite;
  animation: load 0.5s steps(8, end) infinite;
}

.loader-img {
  position: relative;
  top: 5%;
  left: -11%;
}

.loader-file {
  position: relative;
  left: -21%;
}

.container-docs--user {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  background-color: #ff6919;
}


.container-docs--agent {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  background-color: rgba(74, 74, 74, 0.4);
}


@-webkit-keyframes load {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}


@keyframes load {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.download.file {}

img[alt="user-img-container"] {
  /* width: 200px;*/
  width: 100%;
  max-height: 47vh;
}

.text-file-doc {
  margin-top: 8px;
}

input[type=file].input-default:not(.browser-default):focus:not([reandoly]),
input[type=text].input-default:not(.browser-default):focus:not([reandoly]) {
  border-bottom: 1px solid white;
}

input[type=text]:not(.browser-default) {
  text-align: left !important;
}
