.container-all {}

.container-parameters-api {
  border: 1px solid white;
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;

  /*outline: 1px solid blue;*/

}



.container-parameters-api .item_api {
  display: inline-block;
  vertical-align: middle;
  /*outline: 1px solid orange;*/
  width: 200px;
  word-break: break-all;
}

.parameters-api_label {
  margin-right: 20px;
  color: #4a4a4a;
  font-weight: bold;
}

.container-item_options {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.container_ticket_add {
  display: flex;
  justify-content: flex-end;

}

.mr-ticket {
  margin-right: 30px;
}
