.admin-replies__details-replies {
  color: #4a4a4a;
}

.admin-replies__company {
  color: #4a4a4a;
  font-weight: bold;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.09);
  font-size: 16px;
  padding-top: 4px;
}

.admin-replies__subtitle {
  color: #4a4a4a;
  font-weight: bold;
  font-size: 16px;
}

.admin-replies__text {
  font-size: 12px;
}

.container-replies-detail {
  height: 76vh;
  overflow-y: scroll;
  padding-left: 8px;
}

.admin-replies__bordered_bottom {
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
}