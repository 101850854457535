/*.scroll-container {
  outline: 1px solid red;
}*/

.container-scroll {
  display: flex;
  flex-direction: column;

}

.container-message-agent {
  display: flex;
  flex-direction: column;
}

.date-hist {
  margin-top: 2rem;
  font-size: 14px;
}

.hide-loadings {
  display: none;
}


.chatbot-conversation__buttodown {
  position: relative;
  z-index: 1;
  top: 35rem;
  /*bottom: 55px;*/
  /*right: 15px;*/
  /*left: 60rem;*/
  cursor: pointer;

  /*outline: 1px solid red;
  /*background-color: green;
   outline: 1px solid red;*/
  /*cursor: pointer;*/
}

.chatbot-conversation__buttodown:active {
  /* background-color: red;*/
}

.chatbot-conversation__buttodown:focus {
  /* background-color: red;*/
}

.hide-buttodown {
  display: none;
}

.chatbot-conversation__buttodown button {
  background-color: transparent;
  width: 45px;
  outline: none;
  border: none;
  cursor: pointer;

}

.chatbot-conversation__buttodown button.on {
  /*display: block;*/
}

.chatbot-conversation__number {
  /*display: none;*/
  height: 17px;
  width: 17px;
  background: #9d007f;
  color: white;
  border-radius: 50%;
  text-align: center;
  position: absolute;
  top: -5px;
  font-size: 10px;
  padding-top: 1px;
  display: none;
}

.chatbot-conversation__number.on {
  display: block;
}



.chatbot-scrolldown {
  /*display: none;*/
  position: fixed;
  background-color: transparent;

  border: none;
  /*outline: 1px solid red;*/
  bottom: 5rem;
  left: 70%;
  width: 45px;

}

.chatbot-scrolldown:focus {
  background-color: transparent !important;
}

.chatbot-scrolldown.on {
  /*display: block;*/
}

.loading.hist {
  /*position: static;
  margin-bottom: 2rem;
  margin-top: 0rem;*/
  /* margin-bottom: 0rem;*/
  /*top: 5rem;*/
}

.prueba-loading {
  display: none;
}

#loading-historial {
  position: relative;
  /*top: 8rem;*/
}

.hist-loading {
  display: none;
}
