.admin-parameters_input.w-100{
    width: 100%;
}

.admin-parameters_ticket.w-100{
    width: 100%;
}

.admin-parameters__title-seccion{
    font-size: 14px;
    font-weight: bold;
    line-height: 1.36;
    letter-spacing: 0.2px;
    text-decoration: underline;
    padding: 15px .75rem !important;
}
.admin-parameters__title-principal{
    font-size: 14px;
    font-weight: bold;
    line-height: 1.36;
    letter-spacing: 0.2px;
    color: #467dfc;
    padding: 15px .75rem !important;
}