.timercount-chat_title {
    color: #4a4a4a;  
    margin-bottom: 0px;
    margin-top: 0px;
    font-size: 12px;
    font-weight: bold;
    color: #4a4a4a;
}

.timercount-chat_detail {
    color: #4a4a4a;  
    margin-bottom: 0px;
    margin-top: -2px;
    font-size: 8px;
    color: #4a4a4a;
}

.timercount-chat-container{
    margin-left: 20px;
}

  
  @media (min-width: 600px) {
    .timercount-chat_title {
      color: #4a4a4a;
      font-size: 16px;
    }

    .timercount-chat_detail {
        color: #4a4a4a;
        font-size: 12px;
    }
  }


