.container_item-api {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
}

input:not([type]).api {
  border: solid 1px #e3e3e8;
  border-radius: 5px;
  margin-right: 10px;
  margin-left: 10px;
  width: auto;

}

img[ alt="img-close-error"] {
  width: 16px;
  height: 16px;
}

input:not([type]).api:focus {
  border-bottom: solid 1px #e3e3e8;
}
