.container-flex {
  /*display: flex;*/
}

.mb-0 {
  margin-bottom: 0px;
}

.mt-0 {
  margin-top: 0px;
}

.pt-0 {
  padding-left: 0;
  padding-right: 0px;
}

.f-right{
  float: right;
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Roboto'), local('Roboto-Regular'), url(https://fonts.gstatic.com/s/roboto/v20/KFOmCnqEu92Fr1Mu7GxKOzY.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

body {
  background-color: #f8f8fa;
  font-family: Roboto, sans-serif;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  height: 85vh;
}

.hide-icon {
  display: none;
}

/*
.outline {
  outline: 1px solid red;
}
*/

.modal-sm {
  width: 382px !important;
  height: auto !important;
  border-radius: 9px;
  background-color: #ffffff;
  top: 40% !important;
}