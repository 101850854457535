img[alt="img-replies"] {
  width: 22px;
  height: 22px;
}

.admin-replies_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  

}

.modal.modal-container-admin {
 /* width: 382px;
  height: 194px;
  border-radius: 8px;
  text-align: center;
  bottom: 5rem;*/
  width: 380px;
  height: auto;
  background-color: white;
  position: relative;
  left: 40%;
  border-radius: 9px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 18px;
  padding-bottom: 18px;


}

.admin_modal__subtitle {
  font-size: 12px;
  text-align: center;
}

.admin-replies_message {
  width: 70%;
  border: solid 1px #e3e3e8;
  font-size: 14px;
  padding: 10px;
  border-radius: 5px;
  margin-right: 10px;
}

.admin-modal__title {
  color: #606363;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 1rem;
  text-align: center;
}
.admin_replies_container-modal {
  width: 380px;
  height: auto;
  background-color: white;
  position: relative;
  left: 40%;
  border-radius: 9px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 18px;
  padding-bottom: 18px;
}