.info {
  color: #4a4a4a;

  margin-bottom: 0px;
  margin-top: -2px;
  /*font-family: Roboto;*/
  font-size: 8px;
  color: #4a4a4a;
}

@media (min-width: 600px) {
  .info {
    color: #4a4a4a;
    font-size: 12px;
  }
}