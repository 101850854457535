.login {}

.version {

  position: absolute;
  bottom: 0;
  left: 10px;
  font-size: 10px;
}

.container-login {
  display: block;
  margin: 0 auto;
  width: 370px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #f8f8fa;
  margin-top: 8rem;
}

.container-login h3 {
  margin-left: 10px;
  font-size: 42px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a;
}

.label {
  padding-left: 10px;
}

input[type=email]:not(.browser-default),
input[type=password]:not(.browser-default) {
  border-radius: 10px;
  background-color: white;
  width: 350px;
  height: 40px;
  border-bottom: none;
  padding-left: 10px;
}

.btn-login {
  background-color: #608aff;
  width: 222px;
  display: block;
  margin: 0 auto;
  color: white;
  border-radius: 30px;
}

.btn-login:hover,
.btn-login:focus,
.btn-login:active {
  background-color: #8479f4;
}

input[type=text][name=password]:not(.browser-default) {
  border-radius: 10px;
  background-color: white;
  width: 350px;
  height: 40px;
  border-bottom: none;
  padding-left: 10px;
  margin-left: 0;
  margin-bottom: 8px;
  text-align: left;
}

.i-eyes {
  position: absolute;
  top: 8px;
  right: 10px;
  cursor: pointer
}

/*
input::-ms-reveal {
  display:none;
}*/
/* solo para IE*/
::-ms-reveal {
  display: none;
}

.message-error,
.col.s12.message-error {
  background-color: #f47979;
  color: white;
  border-radius: 5px;
  padding: 15px;
  text-align: center;
}

input[type=password].input-default:not(.browser-default):focus:not([reandoly]),
input[type=email].input-default:not(.browser-default):focus:not([reandoly]) {
  border-bottom: 1px solid white;
}
