/*$width: 278px;
*/
.animation-effect {
  /*background-color: red;*/
  -webkit-animation: pulse 1s ease-out;
  animation: pulse 1s ease-out;
}

/***/

.margin-btn {
  margin-bottom: 1rem;
  margin-top: 0rem;
  font-size: 7px;
}

/*
#counter-queue:hover{
    animation: pulse 1s  ease-out;
}*/
/*
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.8), 0 0 0 5px rgba(255, 255, 255, 0.5)
  }

  40% {
    box-shadow: 0 0 0 30px rgba(255, 255, 255, 0), 0 0 0 5px rgba(255, 255, 255, 0)
  }

  80% {
    box-shadow: 0 0 0 30px rgba(255, 255, 255, 0), 0 0 0 5px rgba(255, 255, 255, 0)
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0), 0 0 0 5px rgba(255, 255, 255, 0)
  }
}
*/
.container-request {
  width: 100%;
  /*height: 150px;*/
  height: 22vh;
  /*height: auto;*/
  /*height: 25vh;*/
  /*background: linear-gradient(90deg, #6a60c6, #887cf5);*/

  background: linear-gradient(117deg, #467dfc 1%, #496ffb 97%);
  /*background: linear-gradient(90deg, #6a60c6, #887cf5);*/
  /*background-color: #887cf5;*/
  margin-bottom: 2vh;
  border-radius: 10px;
  /*margin-left: 36px;*/
  color: white;
  text-align: center;
  font-size: 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  /* margin-right: 16px;*/
}


.container-time {
  /*outline: 1px solid red;*/
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  margin-left: 12px;
  margin-right: 12px;
  /*outline: 1px solid;*/
}

.container-time div:nth-child(1) {
  /*width: 79px;*/
}

.container-time div:nth-child(2) {
  width: 50px;
  height: 50px;
  /*background-color: #6357db;*/
  background-color: #5880ff;
  border-radius: 50%;
  text-align: center;
  font-size: 10px;
  margin-top: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  /*margin-left: 4px;
  //margin-right: 0px;*/
  -webkit-box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
}

.container-time div:nth-child(3) {
  /*width: 78px;
  //height: 32px;*/


  /* margin-top: 10px;*/
}

.title-time {
  font-size: 7px;
  text-align: center;
  font-weight: normal;
}

.last-time,
.first-time {
  font-size: 10px;
  text-align: center;
  font-weight: bold;
}

.queue {
  margin-top: 10px;
  /*width: 41px;
    height: 41px;*/
  -webkit-box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
  background-color: #6357db;
  border-radius: 50%;
  font-size: 26px;
  text-align: center;
  /* display: flex;
    justify-content: center;
    align-items: center;*/
}

.btn-request {
  border-radius: 21.5px;
  -webkit-box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.21);
  box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.21);
  background-color: white;
  color: #467dfc;
  /*color: #8479f4;*/
  float: left;
  margin-right: 27px;
  font-size: 7px;
  text-transform: capitalize;
  /*padding: 9px 14px 9px 14px;*/
  width: auto;
  height: 30px;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0 auto;
  margin-top: -10px;
  margin-bottom: 1rem;

}

.btn-request:hover {
  background-color: white;
}

.btn-request:active {
  background-color: white;
}

.btn-request:focus {
  background-color: white;
}

.outline {
  outline: 1px solid orange;
}

@media (min-width: 600px) {
  .outline {
    outline: 1px solid blue;
  }

  .container-request {
    font-size: 14px;
    height: 27vh;
  }

  .title-time {
    font-size: 12px;
  }

  .last-time,
  .first-time {
    font-size: 15px;
  }

  .container-time div:nth-child(1) {
    /*width: 107px;*/
  }

  .container-time div:nth-child(2) {
    /*width: 61px;
    //height: 41px;*/
    font-size: 20px;
    /*margin-left: 23px;
    //margin-right: 28px;*/
  }

  .margin-btn {
    font-size: 14px;
  }

  .btn-request {
    font-size: 13px;
    width: 148px;
    height: 32px;
  }

}

@media (min-width: 992px) {
  .outline {
    outline: 1px solid orange;
  }

  .container-request {
    /*width: 278px;*/
    width: 100%;
    height: 25vh;
    margin-right: 0px;
    /*width: 27vw;*/
    /*margin-left: 36px;*/
  }

  .container-time div:nth-child(1) {
    /*width: 78px;*/
  }

  .container-time div:nth-child(2) {
    /*width: 45px;
    //height: 45px;
    //margin-left: 7px;
    //margin-right: 0px;*/
    /*outline: 3px solid blue;*/
  }



  /*.container-listchat{
        margin-left: 36px;
        width: 278px;
    }*/
}

@media (min-width: 1200px) {
  .outline {
    outline: 1px solid red;
  }



  .container-request {
    width: 100%;
    /*width: 278px;*/
    /*width: 21vw;*/

  }

  .container-time {
    width: 90%;
  }

  .container-time div:nth-child(2) {
    /*width: 56px;
    //height: 56px;
    //margin-left: 30px;
    //margin-right: 28px;
    //outline: 3px solid red;*/
  }

}
