.admin-quick-replies__body {
  background: white;
  min-height: 90vh;
}

.ml-10 {
  margin-left: 10px;
}

.outline {
  outline: 1px solid blue;
}

.admin-quick-replies-navbar {
  height: 61px;
  color: #4a4a4a;
  font-size: 20px;
  font-family: Roboto;
  font-weight: 500;
  box-shadow: 5px 5px 8px 0 rgba(54, 54, 54, 0.06);
  line-height: 3;
}

.admin-quick-replies-navbar a {
  float: right;
  top: 12px;
  right: 15px;
}

.pl-0 {
  padding-left: 0rem !important;
}

.admin-quick-replies-navbar p {
  margin-bottom: 0rem;
  margin-top: 0rem;
  padding-left: 28px;
  font-weight: 600;
  letter-spacing: 0.25px;
}

.admin-replies__container-companies {
  height: 76vh;
  border-right: 1px solid rgba(0, 0, 0, 0.09);
}

.admin-replies__title-companies {
  color: #496ffb;
  height: 54px;
  line-height: 3;
  padding-left: 28px;
  border-bottom: 1px solid #f8f8fa;
  font-weight: 600;
}

.container-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.select-search .sc-htpNat.dePyzG {
  background-color: #dadada;
  padding-top: 15px;
  padding-bottom: 15px;
}

.sc-htpNat.dePyzG input {
  background-color: white;
  /* margin-right: 15px; */
  width: calc(100% - 60px);
  margin-right: 10px;
  margin-left: 10px;
  border-radius: 5px;
  padding: 0px 20px;
}

.btn-royal-blue img {
  top: 4px;
}

.modal-header {
  padding: 24px;
  box-shadow: 5px 5px 8px 0 rgba(54, 54, 54, 0.06);
}

input[type=text]:not(.browser-default) {
  text-align: left !important;
}

input[type=text]:not(.browser-default):focus:not([reandoly]),
input[type=text]:not(.browser-default):active:not([reandoly]) {
  border-bottom: none;
  text-align: left;
}
