.grid-example {
  border-right: 1px solid #d8d8d8;
  margin: 10px 0;
  text-align: center;
  padding: 0;
}

.grid-example-2 {
  border-right: 1px solid white;
  margin: 10px 0;
  text-align: center;
  padding: 0;
}
.Line {
  width: 1px;
  height: 43.2px;
  border: solid 1px #d8d8d8;
  background-color: #d8d8d8;
}

.custom-col {
    width: 14.285%;
    margin-left: auto;
    left: auto;
    right: auto;
}

.Rectangle {
  width: 100%;
  height: 90vh;
  -webkit-box-shadow: 5px 5px 8px 0 rgba(0, 0, 0, 0.09);
  box-shadow: 5px 5px 8px 0 rgba(0, 0, 0, 0.09);
  background-color: #ffffff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  /*outline: 1px solid blue;*/
}



.Imagen {
  width: 100%;
  max-width: 600px;
  height: 500px;

  /*width: 50px;*/
  /*height: 50px;*/
  -o-object-fit: contain;
  object-fit: contain;
}

.two_lines{
  margin:auto;
}

.Inicia-una-conversac {
  /*width: 266px;
  height: 42px;*/
  font-family: Roboto;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #aeabab;
}


.dashboard__container {
  background-color: white;
  margin-left: 1rem;
  min-height: 700px;
}

.dashboard__header {
  height: 61px;
  box-shadow: 5px 5px 8px 0 rgba(54, 54, 54, 0.06);
  color: #4a4a4a;
  font-weight: bold;
  line-height: 3;
  font-size: 18px;

}

.table__two_rows {
  width: 170px;
}

.dashboard__counter {
  height: 95px;
  box-shadow: 0 0 16px 2px rgba(241, 241, 241, 0.71);
  padding-left: 1rem;
  padding-right: 1rem;
}

.dashboard__counter_title {
  color: #5c5c5c;
  font-size: 13px;
  text-align: center;
  margin-top: 0.5rem;
  margin-bottom: 0rem;
}

.dashboard__counter_info {
  color: var(--greyish-brown);
  letter-spacing: 0.25px;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 0rem;
}
.tabs.dashboard__tabs {
  height: 380px;
  /*outline: 1px solid blue;*/
  padding-left: 1rem;
  padding-right: 1rem;
}


.tab.dashboard__tab {
  height: 500px;

}

/*****date***/
.dashboard__container-canvas {
  /*outline: 1px solid red;*/
  box-shadow: 0 0 16px 2px rgba(241, 241, 241, 0.71);

  color: black;


}


/*
canvas {

  outline: 1px solid blue;


  height: 250px !important;


}
*/
button[state=menu] {
  width: 20px !important;
  height: 20px !important;
  display: none !important;
}

.tabs .tab a:focus,
.tabs .tab a:focus.active {
  /*background-color: #e9f0ff;*/
  border-bottom: none !important;
}

.tabs .indicator {
  background-color: #e9f0ff;
}


.dashboard__canvas:focus {
  background-color: #e9f0ff !important;
}

.dashboard__title {
  color: #467dfc;
  text-transform: lowercase;
  text-align: left;
  font-weight: bold;
  margin-top: 0rem;
  margin-bottom: 0rem;
}

/***table****/
.dashboard_table-header {
  text-transform: uppercase;
  color: #4a4a4a;
  font-size: 14px;
  font-weight: lighter;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  margin-top: 5rem;
  text-align: center;
}

.dashboard__table {
  margin-top: 1rem;
}

.dashboard__excel-icon {
  width: 60px;
  margin: 20px auto;
  display: block;
}

.dashboard__download-button {
  background-color: #608aff;
  width: 222px;
  height: 36px;
  display: block;
  margin: 5px auto;
  color: white;
  border-radius: 30px;
  border: none;
}

.dashboard__download-button:hover,
.dashboard__download-button:focus,
.dashboard__download-button:active {
  background-color: #8479f4;
}

td,
th {
  text-align: center !important;
}



.dashboard__container-form {
  margin-top: -6rem;
  width: 90%;
}

.dashboard__canvas {
  width: 700px !important;
}


canvas {
  width: auto !important;
  height: 250px !important;
  position: relative !important;
  left: 3rem !important;
}


.chart_abandoment {
  position: relative !important;
  left: 4rem !important
}

.chart_label_canva {

  text-align: center;

  width: 70%;
  color: #4a4a4a;
  font-weight: bold;
  margin-top: 5rem;
  text-transform: lowercase;
}

.chart_label_canva::first-letter {
  text-transform: capitalize !important;
  /*outline: 1px solid red;*/
}

.tabs .tab a:focus,
.tabs .tab a:focus.active {
  background-color: #e9f0ff;
}

@media (min-width: 600px) {
  .tabs.dashboard__tabs {


    padding-left: 2rem;
    padding-right: 2rem;

  }

  .dashboard__container-canvas {


    width: 300px !important;
    height: 350px !important;
  }

  canvas {
    /*width: 250px !important;
    height: 250px !important;*/
    /* outline: 1px solid blue;*/
    left: 1rem;
  }
}

@media (min-width: 992px) {
  .tabs.dashboard__tabs {


    padding-left: 4rem;
    padding-right: 4rem;
  }

  .dashboard__container-canvas {

    width: 380px !important;
    height: 400px !important;
  }

  canvas {
    /*
    width: 300px !important;
    height: 250px !important;*/
  }
}

@media (min-width: 1200px) {

  .tabs.dashboard__tabs {
    padding-left: 2rem;
    padding-right: 0rem;
  }

  .dashboard__container-canvas {

    width: auto !important;
    height: 400px !important;
    /*outline: 1px solid orange;*/

  }

  .dashboard__container-canvas_r {
    width: 500px !important;
    height: 400px !important;
  }

  canvas {
    /*
    width: 400px !important;
    height: 250px !important;

    left: 3.5rem;*/

  }


}
