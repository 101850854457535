.admin-replies__item-company {
  display: flex;
  align-items: center;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.09);
  height: auto;
  padding-top: 6px;
  padding-bottom: 6px;
}

.admin-replies__item-company:active {
  background-color: #f1f1f1;
}

.admin-replies__img-company {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid #c0c0c0;
  margin-right: 12px;
  margin-left: 14px;
}

img[ alt="logo-company"] {
  border-radius: 50%;
}

.select-search-float .sc-htpNat.dePyzG div{
  top: 45px;
  position: absolute;
  z-index: 1;
}

.input-field-common, input[type=text].input-field-common, input[type=password].input-field-common:not(.browser-default){
  border: 1px solid #e3e3e8;
  border-radius: 2px;
  text-align: left !important;
  margin: 0;
  width: 100%;
  padding-left: 0;
}

.input-field-checkbox {
  height: 47px;
  padding: 12px;
}

.input-field-checkbox span{
  float: left;
}

.input-field-checkbox label{
  float: right;
  left: auto;
  right: 0;
}

.input-field-checkbox  [type="checkbox"].filled-in:checked+span:not(.lever):after {
  border: 2px solid #467dfc;
  background-color: #467dfc;
}