.admin-parameters__container-group {
  display: flex;
  flex-direction: row;
}

.admin-parameters__title-form {
  color: #4a4a4a;
  font-weight: 600;
  font-size: 14px;
}

.mt-20 {
  margin-top: 20px;
}

input:not([type]) {
  border-bottom: none;
}

.hide {
  display: none !important;
}

input:not([type]).input-default:not(.browser-default):focus:not([reandoly]),
input:not([type]).input-default:not(.browser-default):active:not([reandoly]) {
  border-bottom: none;
}
