.channel_title {
    color: #4a4a4a;  
    margin-bottom: 0px;
    margin-top: 0px;
    font-size: 12px;
    font-weight: bold;
    color: #4a4a4a;
  }

  .channel_detail {
    color: #4a4a4a;  
    margin-bottom: 0px;
    margin-top: -2px;
    font-size: 8px;
    color: #4a4a4a;
  }

  .channel-container{
    margin-left: 20px;
  }

  .channel-logo{
    margin-left: 10px;
    vertical-align: text-bottom;
    height: 28px;
    width: 28px;
  }  
  
  @media (min-width: 600px) {
    .channel_title {
      color: #4a4a4a;
      font-size: 16px;
    }

    .channel_detail {
        color: #4a4a4a;
        font-size: 12px;
    }
  }


