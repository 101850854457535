mt-0 {
  margin-top: 0rem;
}

mb-0 {
  margin-bottom: 0rem;
}

.text-report {
  color: #4a4a4a;
}
