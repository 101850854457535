.name {
  color: #4a4a4a;

  margin-bottom: 0px;
  margin-top: 0px;
  /*font-family: Roboto;*/
  font-size: 12px;
  font-weight: bold;
  color: #4a4a4a;
}

@media (min-width: 600px) {
  .name {
    color: #4a4a4a;
    font-size: 16px;
  }
}