.messages {
  /*position: relative;
    display: inline-block;*/
  padding: 11px 16px 5px 16px;
  word-break: break-word;
  font-size: 14px;
  max-width: 60%;
}

.message__row {
  /*z-index: 1;*/
  margin-bottom: 4px;
  /*outline: 5px solid red;*/
}

.message__row:nth-child(1) {
  /*margin-bottom: 0rem;
  /*display: none;*/
  /*margin-top: 7rem;*/
}

.message--in,
.message--END-USER {
  background-color: #608aff;
  color: white;
  border-top-left-radius: 22px;
  border-top-right-radius: 22px;
  border-bottom-right-radius: 22px;
  float: left;
}

.message--in a,
.message--BOT a,
.message--END-USER a {
  color: white;
}

.message--in span a,
.message--BOT span a,
.message--END-USER span a {
  color: white;
  text-decoration: underline;
}

.message--out,
.message--ANALYST {
  background-color: #f1f3f4;
  border-top-left-radius: 22px;
  border-top-right-radius: 22px;
  border-bottom-left-radius: 22px;
  color: #4a4a4a;
  float: right;
}

.message--BOT {
  background-color: #fd7b4d;
  border-top-left-radius: 22px;
  border-top-right-radius: 22px;
  border-bottom-left-radius: 22px;
  color: #ffffff;
  float: right;
}

.message--out span a {
  text-decoration: underline;
}

.message--in .time-message,
.message--BOT .time-message,
.message--END-USER .time-message {
  text-align: right;
  font-size: 9px;
  color: rgba(255, 255, 255, 0.7);
  /*color:rgba(241,243,244, 0.8);*/
}

.message--out .time-message {
  text-align: right;
  font-size: 9px;
  color: rgba(74, 74, 74, 0.4);
}

.icon-read {
  background-image: url(https://canvia.service-now.com/1f7236c0db898410e835c191159619df.iix);
  background-size: 100% auto;
  background-repeat: no-repeat;
  width: 12px;
  height: 13px;
  background-position: 0px 3px;
  position: relative;
  display: block;
  float: right;
  margin-left: 4px;
}

.icon-read.read,
.icon-read.answer {
  background-image: url(https://canvia.service-now.com/3264f2c8db898410e835c191159619d4.iix);
  background-size: 100% auto;
  background-repeat: no-repeat;
  width: 12px;
  height: 13px;
  background-position: 0px 3px;
  position: relative;
  display: block;
  float: right;
  margin-left: 4px;
}