.admin-replies__container-replies {
  width: 400px;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 15rem;
  margin-top: 3rem;
}

img[alt="img-default"] {
  width: 400px;

}

img[ alt="img-replies"] {
  display: table-cell;
  vertical-align: middle
}

.admin-replies__container-replies p {
  color: #4a4a4a;
}
