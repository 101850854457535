.box-chat {
  width: 100%;
  border-bottom: 1.8px solid #eaeaea;
  color: #4a4a4a;
  /*font-family: 'Roboto', sans-serif !important;*/
  /*outline: 2px solid blue;*/

}

.box-chat li {
  height: 67px;
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: 'Roboto', sans-serif !important;

}

.box-color {
  background-color: white;
}

.circle-person {
  width: 26px;
  height: 26px;
  margin-left: 13px;
  margin-right: 11px;
  border: 2px solid #c0c0c0;
  border-radius: 50%;
  /*display: flex !important;
  //justify-content: center;
  //align-items: center;
  text-align: center;
  //background-color: #303750;
  //box-shadow: 0 1px 6px 0 rgba(0, 206, 197, 0.6);*/
  color: white;
  font-size: 15px;
}

img[alt="client"] {
  border-radius: 50%;
}

.circle-person div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}


.personal-information {
  width: 178px;
  /*margin-right: 4px;*/
}

.personal-information p:nth-child(2) {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 12px;
  color: #4a4a4a;
  opacity: 0.5;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.extra-information {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.time {
  color: #4a4a4a;
  font-size: 9px;
  opacity: 0.5;
  margin-bottom: 8px;
}

.message {
  width: 16px;
  height: 16px;
  background-color: #ff5800;
  border-radius: 50%;
  color: white;
  text-align: center;
  font-size: 10px;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.warning-low {
  background-color: #ffebbd;
}

.warning-high {
  background-color: #ffcbaf;
}

.color-item {
  background-color: white;
}

.threshold-low {
  background-color: #ffebbd;
}

.threshold-high {
  background-color: #ffcbaf
}

@media (min-width: 600px) {
  .circle-person {
    width: 31px;
    height: 31px;
  }
}
