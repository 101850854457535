.admin-parameters_container-session {
  margin-top: 10px;
  width: 24vw;

}

.admin-parameters__session-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  width: 100%;
  padding-bottom: 10px;

}

.admin-parameters__session-title {
  color: #4a4a4a;
  font-size: 12px;
  font-weight: 600;
}


.line {
  /* outline: 1px solid orange;*/
}

.admin-parameters__btn-save {
  text-transform: capitalize;
  background-color: #467dfc;
  border-radius: 40px;
  width: 90px;
  height: 35px;
}




.admin-parameters__session-subtitle {
  color: #4a4a4a;
  font-size: 12px;
  margin-top: 0rem;
  margin-bottom: 20px;

}

.mt-10 {
  margin-top: 20px;

}

/**/
.admin-parameters__container-select {

  max-width: 250px;
  position: relative;
}

.admin-parameters__client-default {

  color: #c0c0c0;
  width: 100%;
  border-radius: 10px;
  /*appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;*/

}

.admin-parameters__background {
  /*outline: 1px solid red;*/
  background-color: #f8f8fa;

  /*display: flex;
  justify-content: center;*/
  padding-bottom: 10px;
  padding-top: 10px;
  padding-left: 10px;

}

.modal-save {


  position: fixed;

  z-index: 900;

  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
  /* Fallback color */

  /* Black w/ opacity */
}

.modal-content-save {
  background-color: #fefefe;
  margin: 15% auto;
  /* 15% from the top and centered */
  padding: 20px;
  padding-top: 10px;
  border: 1px solid #888;
  width: 382px;
  height: 214px;
  text-align: center;
  border-radius: 10px;
  /* Could be more or less, depending on screen size */
}

.modal-save-title {
  color: #606363;
  font-weight: bold;
}

.modal-save-close {
  text-align: right;
  font-size: 28px;
  font-weight: bold;
  margin-top: 0rem;
  margin-bottom: 0rem;
  color: #606363;
  cursor: pointer;
}


@media (min-width: 600px) {
  .admin-parameters__session-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 0rem;

  }

  .admin-parameters_container-session {

    width: 55vw;

  }

  .admin-parameters__btn-save {
    width: 116px;
    height: 35px;
  }

  .admin-parameters__session-title {

    font-size: 16px;

  }

  .admin-parameters__session-subtitle {

    font-size: 14px;
  }
}
