.container-quick-replies {
  padding-left: 0px;
  padding-right: 0px;
  color: #606363;
  margin-top: 15px;
  width: 40%;
  height: 78vh;
  overflow-y: scroll;
  overflow-x: visible;
  font-size: 7px;

}

.container-quick-replies::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.container-quick-replies::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 4px;
}

.container-quick-replies div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  color: #606363;
  font-weight: bold;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 10px;
}

img[ alt="icon-drag-drop"] {
  /*width: 23px;*/
  height: 20px;
  margin-right: 10px;
}

.quick-replies {
  color: #606363;
  width: 270px;
  height: auto;
  border-top-left-radius: 22px;
  border-top-right-radius: 22px;
  border-bottom-left-radius: 22px;
  line-height: 1.5;
  -webkit-box-shadow: 0 8px 28px 0 rgba(72, 89, 102, 0.1);
  box-shadow: 0 8px 28px 0 rgba(72, 89, 102, 0.1);
  background-color: #ffffff;
  text-transform: lowercase;
  padding: 9px 14px 9px 14px;
  margin-bottom: 10px;
  text-align: initial;
  font-weight: lighter;
  font-size: 7px;
}

.quick-replies p::first-letter {
  text-transform: capitalize !important;
  /*outline: 1px solid red;*/
}

.quick-replies:hover,
.quick-replies:active {
  -webkit-box-shadow: 0 1px 19px 0 rgba(255, 88, 0, 0.54);
  box-shadow: 0 1px 19px 0 rgba(255, 88, 0, 0.54);
  background-color: #f1f1f1;
}



@media (min-width: 600px) {
  .container-quick-replies {
    font-size: 14px;
    padding-left: 25px;
    padding-right: 25px;

  }



  .quick-replies {
    font-size: 14px;
  }
}

.container-replies {
  display: flex;
  flex-direction: column;
}

.replies-new {
  display: flex !important;
  flex-direction: column !important;
}