/*@import url('https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');
*/


.lateral-menu {
  width: 48px;
  height: 100vh;
  background-color: white;
  -webkit-box-shadow: 0 8px 30px 0 rgba(9, 33, 57, 0.2);
  box-shadow: 0 8px 30px 0 rgba(9, 33, 57, 0.2);
  margin-left: 4px;
}


/*
.sidebar {
  position: absolute;
  width: 220px;
  padding: 20px;

}
*/



.button {
  cursor: pointer;
}

.button svg {
  width: 40px;
}

.nav {
  list-style: none;
  margin-top: 0px;
  margin-bottom: 0px;
  height: 100vh;
}

.nav li a {
  position: relative;
  display: block;
  /* margin-bottom: 5px;*/
  padding: 16px 0 16px 50px;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
}

.nav li a:hover,
.nav li a.active {
  background: rgba(0, 0, 0, .3);
}

.nav li a::before {
  font: 14px fontawesome;
  position: absolute;
  top: 15px;
  left: 20px;
}

.nav li:nth-child(1) a::before {
  content: '\f00a';
}

.nav li:nth-child(2) a::before {
  content: '\f012';
}

.nav li:nth-child(3) a::before {
  content: '\f0e8';
}

.nav li:nth-child(4) a::before {
  content: '\f0c3';
}

.nav li:nth-child(5) a::before {
  content: '\f022';
}

.nav li:nth-child(6) a::before {
  content: '\f115';
}

.nav li:nth-child(7) a::before {
  content: '\f085';
}

.nav li:nth-child(8) a::before {
  content: '\f023';
  left: 23px;
}

#slide-out {
  transform: translateX(0%) !important;
}

.sidenav-container.min-nav .sidenav {
  width: 46px;
}

body[style="overflow: hidden;"] .min-nav .sidenav {
  width: 46px;
}

body[style="overflow: hidden;"] .sidenav {
  width: 300px;
}

.sidenav-container.min-nav .sidenav span {
  display: none;
}

.sidenav-container .sidenav {
  width: 300px;
  background-color: #343434;
  top: 64px;
  height: calc(100% - 64px);
  overflow-y: hidden;
}

.sidenav-container .sidenav span {
  display: block;
  color: #c0c0c0;
}

.sidenav-container~.row {
  width: calc(100% - 46px);
  margin-right: 0;
  padding-top: 64px;
}

.sidenav-container i.icon-menu {
  background-image: url(../../assets/images/circle-right_6@3x.png);
  background-size: 100% auto;
  background-repeat: no-repeat;
  width: 20px;
  /*height: 47px;*/
  height: 20px;
  /*background-position: 0px 10px;*/
}

.sidenav-container i.icon-logout {
  background-image: url(../../assets/images/logout@3x.png);
  background-size: 100% auto;
  background-repeat: no-repeat;
  width: 20px;
  height: 47px;
  background-position: 0px 10px;
}

.sidenav-container i.CHAT {
  background-image: url(../../assets/images/message-plomo.png);
  background-size: 100% auto;
  background-repeat: no-repeat;
  width: 20px;
  height: 47px;
  background-position: 0px 10px;
}

.sidenav-container li.active i.CHAT {
  background-image: url(../../assets/images/message-azul.png);
  background-size: 100% auto;
  background-repeat: no-repeat;
  width: 20px;
  height: 47px;
  background-position: 0px 10px;
}

.sidenav-container i.REPORTS {
  background-image: url(../../assets/images/tramites-plomo.png);
  background-size: 100% auto;
  background-repeat: no-repeat;
  width: 20px;
  height: 47px;
  background-position: 0px 10px;
}

.sidenav-container li.active i.REPORTS {
  background-image: url(../../assets/images/tramites-azul.png);
  background-size: 100% auto;
  background-repeat: no-repeat;
  width: 20px;
  height: 47px;
  background-position: 0px 10px;
}

.sidenav-container i.DASHBOARD {
  background-image: url(../../assets/images/pie-a-plomo.png);
  background-size: 100% auto;
  background-repeat: no-repeat;
  width: 20px;
  height: 47px;
  background-position: 0px 10px;
}

.sidenav-container li.active i.DASHBOARD {
  background-image: url(../../assets/images/pie-a-azul.png);
  background-size: 100% auto;
  background-repeat: no-repeat;
  width: 20px;
  height: 47px;
  background-position: 0px 10px;
}

.sidenav-container i.USERS-SETTINGS {
  background-image: url(../../assets/images/people-three-plomo.png);
  background-size: 100% auto;
  background-repeat: no-repeat;
  width: 20px;
  height: 47px;
  background-position: 0px 10px;
}

.sidenav-container li.active i.USERS-SETTINGS {
  background-image: url(../../assets/images/people-three-azul.png);
  background-size: 100% auto;
  background-repeat: no-repeat;
  width: 20px;
  height: 47px;
  background-position: 0px 10px;
}

.sidenav-container i.COMPANIES-SETTINGS {
  background-image: url(../../assets/images/settings-plomo.png);
  background-size: 100% auto;
  background-repeat: no-repeat;
  width: 20px;
  height: 47px;
  background-position: 0px 10px;
}

.sidenav-container li.active i.COMPANIES-SETTINGS {
  background-image: url(../../assets/images/settings-azul.png);
  background-size: 100% auto;
  background-repeat: no-repeat;
  width: 20px;
  height: 47px;
  background-position: 0px 10px;
}

.sidenav-container i.REPLIES-SETTINGS {
  background-image: url(../../assets/images/editar-plomo.png);
  background-size: 100% auto;
  background-repeat: no-repeat;
  width: 20px;
  height: 47px;
  background-position: 0px 10px;
}

.sidenav-container li.active i.REPLIES-SETTINGS {
  background-image: url(../../assets/images/editar-azul.png);
  background-size: 100% auto;
  background-repeat: no-repeat;
  width: 20px;
  height: 47px;
  background-position: 0px 10px;
}

/* F.G.H - Creacion de nuevo menu - Estados de Analistas - 22/02/22 */
.sidenav-container i.ANALYST_STATUS {
  background-image: url(../../assets/images/plomo.png);
  background-size: 100% auto;
  background-repeat: no-repeat;
  width: 20px;
  height: 47px;
  background-position: 0px 10px;
}

.sidenav-container li.active i.ANALYST_STATUS {
  background-image: url(../../assets/images/analys_status.png);
  background-size: 100% auto;
  background-repeat: no-repeat;
  width: 20px;
  height: 47px;
  background-position: 0px 10px;
}

.sidenav-container.min-nav .sidenav li {
  pointer-events: none;
}

.sidenav-container .sidenav li>a {
  padding: 0px 13px;
}

.sidenav-container li.active a {
  border-left: 4px solid #608aff;
  padding: 0px 9px;
}

.sidenav-container li.active a span,
.sidenav-container li.active a span {
  color: #608aff;
}

.sidenav-container .sidenav .sidenav-menu {
  border: none;
  height: 100px;
}

.sidenav-container .sidenav .sidenav-menu a {
  padding: 20px 10px;
}

.sidenav-container .sidenav .sidenav-menu a i {
  cursor: pointer;
  transform: rotate(180deg);
  transition-duration: 0.5s;
}

.sidenav-container.min-nav .sidenav .sidenav-menu a i {
  transform: rotate(0deg);
  transition-duration: 0.5s;
}

.sidenav-container .sidenav .sidenav-menu.active a {
  border: none;
  padding: 10px 10px;
}

.sidenav-container .sidenav .sidenav-logout {
  /*padding-top: 100px;*/
}

.sidenav-container .sidenav .sidenav-logout a {
  /*padding-top: 100px;*/
  cursor: pointer;
}
