.admin-parameters__body {
  background-color: white;

}

.ml-10 {
  margin-left: 10px;
}

.outline {
  outline: 1px solid blue;
}

.admin-parameters-navbar {
  height: 61px;
  color: #4a4a4a;
  font-size: 12px;
  font-family: Roboto;
  font-weight: 500;

  line-height: 5;
  background-color: white;
  /*border-bottom: 2px solid rgba(54, 54, 54, 0.06);*/

}

.prueba-navbar {}

.pl-0 {
  padding-left: 0rem !important;
  border-bottom: none !important;
}

.admin-parameters-navbar p {
  margin-bottom: 0rem;
  margin-top: 0rem;
  padding-left: 28px;
  font-weight: 600;
  letter-spacing: 0.25px;
  box-shadow: 5px 10px 8px 0px rgba(54, 54, 54, 0.06);

}

.admin-parameters__container-companies {
  height: auto;
  border-right: 1px solid rgba(0, 0, 0, 0.09);
}

.admin-replies__title-companies {
  color: #496ffb;
  height: 54px;
  line-height: 3;
  padding-left: 28px;
  border-bottom: 1px solid #f8f8fa;
  font-weight: 600;
}

.container-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

/*****************************/
.prueba-tabs {
  display: flex;
  flex-direction: column;
  height: auto !important;
  border-right: 1px solid rgba(0, 0, 0, 0.09);
  margin-top: 10px;
}

.admin-parameters__title p {
  color: #496ffb !important;

  border-bottom: 1px solid #f8f8fa;
  font-weight: 600;
  white-space: normal;
  font-size: 12px;
  padding-left: 10px;
  height: 54px;
  line-height: 2;
  text-transform: lowercase;
  text-align: left;
  margin-left: 15px;
  ;
}

.admin-parameters__tab {
  height: auto !important;
}

.admin-parameters__tab:active,
.admin-parameters__tab :focus {
  background-color: #e9f0ff;
  border-left: 5px solid #457cfb;
}

.tabs .tab a.admin-parameters__item.active,
.tabs .tab a.admin-parameters__item:focus.active {
  background-color: #e9f0ff;
  border-left: 5px solid #457cfb;
}

.admin-parameters__item {
  color: #4a4a4a !important;
  text-transform: lowercase !important;
  font-weight: 600;
  font-size: 12px !important;
  white-space: normal;
  border-bottom: 1px solid #f8f8fa;
  text-align: left !important;
  line-height: 2;
}

.admin-parameters__item p:first-letter,
.admin-parameters__title p:first-letter {
  text-transform: uppercase;
  margin-block-start: 0em !important;
  margin-bottom: 0em !important;
}

.mt-0,
.mb-0 {
  margin-top: 0rem;
}

.pl-0 {
  padding-left: 0rem;
}

.mr-0 {
  margin-right: 0rem;
}

@media (min-width: 600px) {
  .admin-parameters-navbar {

    font-size: 20px;
    line-height: 3;
  }

  .admin-parameters__title p {

    /*font-size: 14px;*/
    line-height: 4;
    /*padding-left: 20px;*/
  }

  .admin-parameters__tab {
    height: 54px !important;
  }

  .admin-parameters__item {

    line-height: 4;
  }
}

@media (min-width: 992px) {





  .admin-parameters__item,
  .admin-parameters__title p {
    font-size: 13px !important;

  }

  .admin-parameters__title p {

    margin-left: 25px;
  }
}
