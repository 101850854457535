.admin-parameters_ticket {
  border-radius: 5px;
  border: 2px solid #e3e3e8;
  width: 130px;
  margin-left: 0rem;
  display: flex;
  flex-direction: row;
  color: #c0c0c0 !important;
  padding-right: 5px;
  font-weight: 600;
  height: 50px;
  margin-right: 5px;
  align-items: center;
  margin-bottom: 5px;
}

.admin-parameters_ticket input:not([type]) {

  margin-bottom: 0rem;
  color: #c0c0c0;
  padding-left: 4px;
}


.admin-parameters_ticket input:not([type]):focus {
  color: #4a4a4a;
}

input:not([type]).input-default:not(.browser-default):focus:not([reandoly]),
input:not([type]).input-default:not(.browser-default):active:not([reandoly]) {
  border-bottom: none;
}

.admin-parameters_asterisk {
  color: #496ffb;
  font-size: 20px;
  margin-left: 5px;
  font-weight: 600;
}

img.responsive-img.img-close {
  width: 12px;
  height: 12px;
}

@media (min-width: 600px) {}
