.dashboard__row {
  color: #4a4a4a;
  font-size: 14px;
  text-align: center;
}

.dashboard__state {

  border-radius: 16px;
  width: auto;
  height: 40px;
  text-align: center;
  margin-top: 0rem;
  margin-bottom: 0rem;
  margin-right: 0rem;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 3;

}

.dashboard__stateAgente {
  width: auto;
  height: 40px;
  text-align: center;
  margin-top: 0rem;
  margin-bottom: 0rem;
  margin-right: 0rem;
}

.dashboard__stateAgente--disponible {
  color: #008E89;
}

.dashboard__stateAgente--attend {
  color: #ffa778;
}

.dashboard__state--attend {
  background-color: #e9f0ff;
}

.dashboard__state--delay {
  background-color: #ffa778;
}

.dashboard__state--waiting {
  background-color: #ffdc8c
}

.threshold-none {
  background-color: white;
}

.dashboard__state--without-attend {
  background-color: #c0c0c0;
}

.dashboard__column--close {
  /*background-color: red;*/
  display: none;
}

.hide {
  background-color: red;
}

.threshold-first {

  background-color: #ffdc8c;
}


.threshold-second {
  background-color: #ffa778;
}

.hide-threshold {
  display: none;
}

.show-item {
  display: block;
}

.low {
  display: none;
}
