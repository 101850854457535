.admin-parameters_container-session {
  margin-top: 10px;
  width: 24vw;

}

.admin-parameters__session-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  width: 100%;
  padding-bottom: 10px;

}

.admin-parameters__session-title {
  color: #4a4a4a;
  font-size: 12px;
  font-weight: 600;
}


.line {
  /*outline: 1px solid orange;*/
}


.admin-parameters__btn-save-input {
  text-transform: capitalize;
  background-color: #467dfc;
  border-radius: 40px;
  width: 90px;
  height: 35px;



}

.position-btn {
  left: 50%;
}

.admin-parameters__btn-save-input:hover,
.admin-parameters__btn-save-input:active,
.admin-parameters__btn-save-input:focus {
  background-color: #467dfc;
}

.admin-parameters__btn-save-input p {
  margin-top: 0rem;
  margin-bottom: 0rem;

}

.admin-parameters__btn-save p:first-letter {
  text-transform: uppercase;
}

.admin-parameters__session-subtitle {
  color: #4a4a4a;
  font-size: 12px;
  margin-top: 0rem;
  margin-bottom: 20px;

}

.mt-10 {
  margin-top: 20px;

}

.admin-parameters__container-thresholds {
  margin-top: 20px;
}

.text-blue-low {
  color: #467dfc;
  font-size: 14px;
  font-weight: bold;
}

@media (min-width: 600px) {
  .admin-parameters__session-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 0rem;

  }

  .admin-parameters_container-session {

    width: 55vw;

  }

  .admin-parameters__btn-save-input {
    width: 116px;
    height: 35px;
  }

  .admin-parameters__session-title {

    font-size: 16px;

  }

  .admin-parameters__session-subtitle {

    font-size: 14px;
  }
}

@media (min-width: 992px) {
  .admin-parameters__btn-save-input {}

  .position-btn {
    left: 140%;
  }
}

/*
.outlinel {
  outline: 2px solid blue;
  margin-bottom: 10px;
}
*/
