.container-chat {
  width: 100%;
  /*width: 68vw;*/
  /*height: 500px;*/
  height: 90vh;
  background-color: white;
  -webkit-box-shadow: 5px 5px 8px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 5px 5px 8px 0 rgba(0, 0, 0, 0.1);
  margin-top: 4px;
  /*margin-left: 1rem;*/
}

.container-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.header-chat {
  width: 100%;
  /*height: 56px;*/
  height: 10%;
  -webkit-box-shadow: 5px 5px 8px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 5px 5px 8px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 0.8rem;
}

.header-chat .item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.btn-register {
  border-radius: 30px;
  background-color: #8479f4;
  float: left;
  margin-right: 27px;
  font-size: 13px;
  text-transform: capitalize;
  padding: 9px 14px 9px 14px;
  width: 149px;
  height: 34px;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.btn-register p {
  margin: 0px;
}

.btn-register p::first-letter {
  text-transform: capitalize !important;
  margin-top: 0px;
  margin-bottom: 0px;
}

.btn-register:hover {
  background-color: #8479f4;
}

.body {
  width: 60vw;
  /*width: 80vw;*/
  border: 1px solid #f1f1f1;
  border-top: none;
  height: 81vh;
  overflow: hidden;
  border: 1px solid #f1f1f1;
  border-top: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 0 auto;
  padding-bottom: 0;
}

.body-chat {
  /*display: flex;
    flex-flow: column nowrap;
    background-color: white;
    padding-right: 19px;
    padding-left: 19px;
    overflow-y: scroll;
    overflow-x: visible;*/

  height: 70vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  /* align-items: flex-start; */
  /* overflow-x: hidden; */
  /* height: 388px; */
  /* height: 500px;*/
  /* max-height: 73vh; */
  background-color: white;
  padding-right: 19px;
  padding-left: 19px;
  overflow-y: scroll;
  /*overflow-x: visible;*/
  /*height: 85vh;*/
  /*outline: 1px solid red;*/
  /*prueba*/
  /*position: relative;
  bottom: 0;
  overflow-y: scroll;
  max-height: 450px;
  outline: 1px solid red;*/
  padding-bottom: 8px;

}

.body-chat::-webkit-scrollbar {
  width: 10px;
  /* Tamaño del scroll en vertical */
  /*height: 2px;*/
  /* Tamaño del scroll en horizontal */
}

.body-chat::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 4px;
}


/* Cambiamos el fondo y agregamos una sombra cuando esté en hover */

.body-chat::-webkit-scrollbar-thumb:hover {
  background: #b3b3b3;
  -webkit-box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}


/* Cambiamos el fondo cuando esté en active */

.body-chat::-webkit-scrollbar-thumb:active {
  background-color: #999999;
}

.body-chat::-webkit-scrollbar-track {
  background: #e1e1e1;
  border-radius: 4px;
}


/* Cambiamos el fondo cuando esté en active o hover */

.body-chat::-webkit-scrollbar-track:hover,
.body-chat::-webkit-scrollbar-track:active {
  background: #d4d4d4;
}

.chat-input {
  /*height: 5.5vh;*/
  /*height: 3vh;*/
  /*height: 9vh;*/
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: rgba(96, 99, 99, 0.63);
}

input[type=text]:not(.browser-default) {
  border: none;
  color: #4a4a4a;
  margin-bottom: 0px;
  margin-left: 10px;
  /* cursor: pointer;*/
}

input:focus {
  /* border-bottom: none !important;*/
  -webkit-box-shadow: none !important;
  box-shadow: none !important
}

img[alt="emoji"] {
  width: 20px;
  height: 20px;
  margin-left: 15px;
  margin-right: 10px;
  cursor: pointer;
}

img[alt="clip"] {
  width: 15px;
  height: 20px;
  margin-right: 15px;
  cursor: pointer;
}

.separacionchat {
  width: 100%;
  height: 100px;
  outline: 1px solid red;
  /*display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;*/
}

.separator-img {
  width: 31px;
  height: 31px;
  border: 1px solid #f1f1f1;
  border-radius: 50%;
}

img[alt="person"] {
  border-radius: 50%;
  width: 34px;
  /*border:1px solid #f1f1f1; */
}

.agent-avatar-img {
  display: block;
}

.agent-avatar-img::before {
  content: "soy un texto azul delante";
  padding: 5px;
  border: 1px solid blue;
  margin-right: 5px;
  color: blue;
  display: inline-block;
}

/*.separacionchat::before {*/
/*
.agent-avatar-img::before {
  content: " ";
  display: inline-block;
  width: 50%;
  height: 2px;
  background: #f1f1f1;
  margin-right: 6px;
}
*/

/*
.separacionchat+*:before {
  content: " ";
  display: inline-block;
  width: 100%;
  height: 2px;
  background: #f1f1f1;
  margin-right: 6px;
}*/
/*
.agent-avatar-img::after {
  content: " ";
  display: inline-block;
  width: 50%;
  height: 2px;
  background: #f1f1f1;
  margin-left: 6px;
  outline: 1px solid red;
}*/

/*
.separacionchat+*:after {
  content: " ";
  display: inline-block;
  width: 100%;
  height: 2px;
  background: #f1f1f1;
  margin-left: 6px;
}*/

.send {
  background-color: white;
  border: none;
}

.send:active {
  background-color: white;
}

.send:focus {
  background-color: white;
}

.finish-conversation {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.finish-conversation button {
  margin-right: 20px;
  background-color: #8479f4;
  color: white;
  font-size: 16px;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 16px;
  text-transform: lowercase;
  -webkit-box-shadow: -1px 0 14px 0 rgba(0, 0, 0, 0.09);
  box-shadow: -1px 0 14px 0 rgba(0, 0, 0, 0.09);
  /*padding: 10px 13px 10px 13px;*/
}

.finish-conversation button p::first-letter {
  text-transform: capitalize !important;
}

.capitalize {
  text-transform: capitalize !important;
}

.finish-conversation button:hover {
  background-color: #8479f4;
}

.finish-conversation button:focus {
  background-color: #8479f4;
}

.finish-conversation button:active {
  background-color: #8479f4;
}


/*liberia emojipicker*/

.emoji-box {
  /*outline: 1px solid red;*/
  z-index: 3;
  /* position: relative;;
    left:20px;
    bottom: 380px;*/
  /*width: 312px !important;*/
  position: absolute;
  left: 320px;
  bottom: 50px;
  font-size: 12px;
  font-family: 'Roboto', sans-serif !important;
  border: none !important;
  /*outline: 1px solid red;*/
  /*box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.09);*/
}

.triangulo-2 {
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 19px solid white;
  background-color: transparent;
  z-index: 3;
  /*outline: 1px solid red;*/
  right: 62rem;
  -webkit-box-shadow: 7px 7px 11px 0px rgba(112, 111, 111, 0.3);
  box-shadow: 7px 7px 11px 0px rgba(112, 111, 111, 0.3);
  outline: 1px solid red;
  z-index: 3;
}

img[alt="triangle-header"] {
  width: 20%;
}

.emoji-mart {
  font-family: 'Roboto', sans-serif !important;
  font-size: 12px;
  border: 1px solid white;
  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  /*box-shadow: 10px 10px rgba(0, 0, 0, 0.3);*/
}

.emoji-mart-anchors {
  height: 35px;
}

.emoji-mart-search {
  height: 30px;
}

.emoji-mart-search input[type=search]:not(.browser-default) {
  height: 2rem;
  border-bottom: none;
  font-size: 12px;
}

.emoji-mart-scroll::-webkit-scrollbar {
  width: 0.65em;
  height: 0.65em;
}

.emoji-mart-scroll::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
}

.emoji-mart-scroll::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.3);
}

.emoji-mart-bar:last-child {
  display: none;
}

.emoji-mart-bar .nav {
  background-color: white !important;
}

button:active {
  /*background-color: white;*/
}

button:focus {
  /* background-color: white;*/
}

.modal-ticket {
  width: 382px !important;
  height: auto !important;
  border-radius: 9px;
  background-color: #ffffff;
  top: 40% !important;
}

.modal-finish-conversation {
  width: 382px !important;
  height: 270px !important;
  border-radius: 9px;
  background-color: #ffffff;
  top: 40% !important;
}

.container-modal-finish {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.container-modal-finish button {
  margin-bottom: 10px;
}

.btn-line-royal {
  color: #5084fc;
  border: 1px solid #5084fc;
  background-color: white;
  border-radius: 30px;
  text-transform: lowercase;
  margin-right: 10px;
  font-size: 7px;
  box-shadow: none;
}

.mr-0 {
  margin-right: 0px;
}

.btn-line-royal:focus,
.btn-line-royal:active,
.btn-line-royal:hover {
  color: #5084fc;
  border: 1px solid #5084fc;
  background-color: white;
}

.mr-2 {
  margin-right: 16px;
}

img[alt="close-modal"] {
  width: 23px;
  height: 23px;
  margin-top: 12px;
}

.btn-royal-blue {
  background-color: #467dfc;
  color: white;
  text-transform: lowercase;
  border-radius: 30px;
  font-size: 14px;
  padding: 0px 13px 9px 13px;
}

.btn-royal-blue:hover {
  background-color: #467dfc;
}

.text-royal-blue {
  color: #496ffb;
}

.btn-royal-blue:active {
  background-color: #467dfc;
}

.btn-royal-blue:focus {
  background-color: #467dfc;
}

.btn-royal-blue p::first-letter,
.btn-line-royal p::first-letter {
  text-transform: capitalize !important;
}

.btn-size-modal {
  width: 138px;
  height: 34px;
  margin-right: 12px;
}

.size-ticket {

  font-size: 7px;
}

.container-modal {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  /*flex-direction: column !important;*/
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #606363;
  text-align: center;
  justify-content: center;
}

.column {
  flex-direction: column !important;
}

.modal-close.position {
  position: absolute;
  right: 0%;
  color: #628bff;
  font-size: 18px;
  margin-bottom: 17px;
}

img[alt="agent-disconect"] {
  width: 80%;
  display: block;
  margin: 0 auto;
}

.container-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-top: 25px;
}

.click-state {
  /*background-color: red;*/
}

@media (min-width: 600px) {
  .container-chat {
    margin-top: 0px;
  }

  .size-ticket {
    width: 141px;
    height: 34px;
    font-size: 14px;
  }

  .btn-line-royal {
    font-size: 14px;
  }
}

@media (min-width: 992px) {
  .container-chat {
    margin-left: 0rem;
    margin-right: 30px;
  }

  .body {
    width: 42vw
  }

  .chat-input {
    /* height: 5.5vh;*/
    /*outline: 1px solid red;*/
  }

  .modal-ticket {
    width: 382px !important;
  }
}

@media (min-width: 1200px) {
  .body {
    width: 55vw
  }

  .modal-ticket {
    width: 382px !important;
  }

  .chat-input {
    /*height: 5.5vh;*/
  }
}


/*********loading********/

.loading {
  /*position: fixed;*/
  z-index: 999;
  position: relative;
  margin-top: 2rem;
  margin-bottom: 3rem;
  left: 50%;
  width: 30px;
  /*outline: 1px solid red;*/
}


/* Transparent Overlay */

.loading:before {
  content: '';
  display: block;
}


/* :not(:required) hides these rules from IE9 and below */

.loading:not(:required),
.chatbot-conversation_loading-agent:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
}

.loading:not(:required):after,
.chatbot-conversation_loading-agent:not(:required):after {
  content: '';
  display: block;
  font-size: 8px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 1500ms infinite linear;
  animation: spinner 1500ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: #ff5800 1.5em 0 0 0, #ff5800 1.1em 1.1em 0 0, #ff5800 0 1.5em 0 0, #ff5800 -1.1em 1.1em 0 0, #ff5800 -1.5em 0 0 0, #ff5800 -1.1em -1.1em 0 0, #ff5800 0 -1.5em 0 0, #ff5800 1.1em -1.1em 0 0;
  box-shadow: #ff5800 1.5em 0 0 0, #ff5800 1.1em 1.1em 0 0, #ff5800 0 1.5em 0 0, #ff5800 -1.1em 1.1em 0 0, #ff5800 -1.5em 0 0 0, #ff5800 -1.1em -1.1em 0 0, #ff5800 0 -1.5em 0 0, #ff5800 1.1em -1.1em 0 0;
}


/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/**/
.img-agent-separator {
  width: 30px;
  /*outline: 1px solid red;*/
  margin-left: 10px;
  margin-right: 10px;

}

.div-after {
  font-weight: bold;
  font-size: 22px;
  display: flex;
  align-items: center;
  padding-bottom: 40px;
  padding-top: 20px;
}

.div-after::before {
  /*content: "soy un texto azul";
  padding: 5px;
  border: 1px solid blue;
  margin-right: 5px;
  color: blue;
  display: inline-block;*/
  content: " ";
  display: inline-block;
  width: 50%;
  height: 2px;
  background: #f1f1f1;
  /*margin-left: px;*/
}

.div-after::after {
  /*content: " ";
  width: 100px;
  height: 5px;
  padding: 5px;
  background: red;
  /*border: 1px solid red;*/
  /*margin-left: 5px;
  /*color: red;*/
  content: " ";
  display: inline-block;
  width: 50%;
  height: 2px;
  background: #f1f1f1;
}

.title-quick-replies {
  font-family: Roboto;
  font-size: 14px;

  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  font-weight: bold;
  color: #4a4a4a;


}

.title-quick-replies:after {
  content: "";
  display: block;
  height: 0.5em;
  vertical-align: bottom;
  width: 100%;
  margin-right: -100%;
  margin-left: 10px;
  margin-top: 5px;
  border-top: 2px solid #f8f8fa;
}

/*
.agent-avatar-img::after {
  content: " ";
  display: inline-block;
  width: 50%;
  height: 2px;
  background: #f1f1f1;
  margin-left: 6px;
  outline: 1px solid red;
}*/
